<template>
    <div>
        <TableWrap @callback="handlePagination" :total="total">
            <template slot="tablesearch">
                <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
            </template>
            <template slot="tableOperate">
                <el-button type="primary" size="small" @click="handleCreate">新增</el-button>
            </template>
            <el-table :data="tableData" :header-cell-style="{background:'#FAFAFA'}" v-loading="loading" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="username" label="登录名"> </el-table-column>
                <el-table-column prop="name" label="用户姓名"> </el-table-column>
                <el-table-column prop="phone" label="移动电话"> </el-table-column>
                <el-table-column prop="email" label="邮箱"> </el-table-column>
                <el-table-column prop="deptName" label="部门名称"> </el-table-column>
                <el-table-column prop="tel" label="用户状态">
                    <template v-slot="scope">
                        {{ $utils.getDicValue('USER_LOCK_FLAG', scope.row.lockFlag ).mean }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="170" align="center">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="handleEdit(scope.row.userId)">编辑 </el-button>
                        <el-dropdown trigger="hover" @command="handleCommand">
                            <el-button type="text" size="small">
                                更多<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="`detail&${scope.row.userId}`">详情</el-dropdown-item>
                                <el-dropdown-item :command="`password&${scope.row.username}`">密码</el-dropdown-item>
                                <el-dropdown-item :command="`delete&${scope.row.userId}`">删除</el-dropdown-item>
                                <el-dropdown-item :command="`freeze&${scope.row.userId}&${scope.row.lockFlag}`">
                                    {{ scope.row.lockFlag === '0' ? '冻结': '解冻' }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </TableWrap>

        <CedUser @callback="handleCedUser" :cedDrawVisible="cedDrawVisible" :operateType="operateType" :id="currentId" :allRoles="allRoles"/>
        <Password :passwordDiaVisible.sync="passwordDiaVisible" :username="currentUsername"/>
    </div>
</template>

<script>
import tableMixIn from '@/mixIn/tableMixIn'
import { reqUsers, reqUsersDelete, reqUsersFreeze, reqUsersUnFreeze } from '@/api/system/user'
import { reqRoles } from '@/api/system/role'
import CedUser from '@/views/system/user/cedUser'
import Password from '@/views/system/user/password'
export default {
    /* 用户管理 */
    name: 'User',

    components: { CedUser, Password },

    mixins: [tableMixIn],

    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'name', label: '用户名' },
                { type: 'input', key: 'username', label: '登录名' },
                { type: 'input', key: 'phone', label: '手机号' },
                { type: 'select', key: 'lockFlag', label: '锁定状态', clearable: true, options: [{ key: 0, value: '正常' }, { key: 1, value: '锁定' }] }
            ],
            reqTableData: reqUsers,
            cedDrawVisible: false,
            operateType: 'create',
            currentId: null,
            currentUsername: '',
            passwordDiaVisible: false,
            statusList: [],
            allRoles: []
        }
    },

    created () {
        this.$utils.getDicData('USER_LOCK_FLAG').then((result) => {
            this.statusList = result
            this.getTableData()
        })

        this.getRoles()
    },

    methods: {
        getRoles () {
            reqRoles()
                .then(({ data }) => {
                    this.allRoles = data.map(v => ({ id: v.roleId, name: v.roleName }))
                })
        },

        handleCreate () {
            this.operateType = 'create'
            this.cedDrawVisible = true
        },

        handleEdit (id) {
            this.currentId = id
            this.operateType = 'edit'
            this.cedDrawVisible = true
        },

        handleDetail () {
            this.operateType = 'detail'
            this.cedDrawVisible = true
        },

        /* 弹窗回调 */
        handleCedUser (param) {
            this.cedDrawVisible = false
            this.getTableData()
        },

        handleDeleteFreeze (type, status) {
            const txt = type === 'freeze' ? '冻结' : '删除'
            this.$confirm(`确认${txt}？`, txt, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const { currentId } = this
                if (type === 'freeze') {
                    if (status === '0') {
                        reqUsersFreeze({ id: currentId })
                            .then(() => {
                                this.getTableData()
                            })
                    } else {
                        reqUsersUnFreeze({ id: currentId })
                            .then(() => {
                                this.getTableData()
                            })
                    }
                } else {
                    reqUsersDelete({ id: currentId })
                        .then(() => {
                            this.getTableData()
                        })
                }
            }).catch(() => {})
        },

        handleCommand (command) {
            command = command.split('&')
            this.currentId = command[1]
            if (command[0] === 'detail') {
                this.handleDetail()
            } else if (command[0] === 'password') {
                this.currentUsername = command[1]
                this.passwordDiaVisible = true
            } else if (command[0] === 'freeze') {
                this.handleDeleteFreeze('freeze', command[2])
            } else if (command[0] === 'delete') {
                this.handleDeleteFreeze('delete')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
