<template>
    <div>
        <el-dialog
            title="重新设定密码"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="dialogBeforeClose">
            <div>
                <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
                    <!-- <el-form-item label="用户账号" prop="username"> <el-input v-model="formData.username"></el-input></el-form-item> -->
                    <el-form-item label="旧密码" prop="password"> <el-input size="small" v-model="formData.password"></el-input></el-form-item>
                    <el-form-item label="新密码" prop="newpassword1"> <el-input size="small" v-model="formData.newpassword1"></el-input></el-form-item>
                    <el-form-item label="确认新密码" prop="surePassword"> <el-input size="small" v-model="formData.surePassword"></el-input></el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleSure">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { reqUserInfoUpdate } from '@/api/system/user'

export default {
    /* 密码修改 */
    name: 'Password',

    props: {
        username: {
            type: String,
            default: ''
        },
        passwordDiaVisible: {
            type: Boolean,
            default: false
        }
    },

    data () {
        const validateFn = (rule, value, callback) => {
            const { field } = rule
            if (field === 'newpassword1') {
                const { password, newpassword1 } = this.formData
                if (password !== newpassword1) {
                    callback()
                    return
                }
                callback(new Error('请输入与旧密码不同的新密码'))
            } else if (field === 'surePassword') {
                const { newpassword1, surePassword } = this.formData
                if (surePassword === newpassword1) {
                    callback()
                    return
                }
                callback(new Error('请确认新密码一致'))
            } else {
                callback()
            }
        }
        return {
            dialogVisible: false,
            formData: {
                username: '',
                password: '',
                newpassword1: '',
                surePassword: ''
            },
            rules: {
                username: [{ required: true, trigger: 'blur', message: '请输入用户账号' }],
                password: [{ required: true, trigger: 'blur', message: '请输入旧密码' }],
                newpassword1: [{ required: true, trigger: 'blur', validator: validateFn }],
                surePassword: [{ required: true, trigger: 'blur', validator: validateFn }],
            }
        }
    },

    watch: {
        passwordDiaVisible (v) {
            this.dialogVisible = v
        }
    },

    methods: {
        dialogBeforeClose () {
            this.handleCallback()
        },

        handleCallback () {
            this.$emit('update:passwordDiaVisible', false)
        },

        handleSure () {
            const { formData } = this
            reqUserInfoUpdate({
                username: this.username,
                password: formData.password,
                newpassword1: formData.newpassword1
            })
                .then(result => {
                    this.handleCallback()
                    this.$message.success('成功')
                })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
