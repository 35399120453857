<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawerVisible"
            direction="rtl"
            :wrapperClosable="false"
            custom-class="cus-drawer"
            :before-close="handleClose">
            <!-- <div class="cus_drawer_container"> -->
                <div class="cus_drawer_content">
                    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
                        <el-form-item label="登录名" prop="username">
                            <el-input size="small" v-model="formData.username" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item v-if="operateType === 'create'" label="密码" prop="password">
                            <el-input size="small" v-model="formData.password" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item label="用户姓名" prop="name">
                            <el-input size="small" v-model="formData.name" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item label="移动电话" prop="phone">
                            <el-input size="small" v-model="formData.phone" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input size="small" v-model="formData.email" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item label="固定电话">
                            <el-input size="small" v-model="formData.tel" :disabled="isDetail"></el-input>
                        </el-form-item>
                        <el-form-item label="部门" prop="deptId">
                             <el-cascader
                                v-model="val"
                                :options="options"
                                ref="myCascader"
                                :props="{
                                    checkStrictly:true,
                                    children: 'children',
                                    value: 'id',
                                    label: 'name',
                                    emitPath: false
                                }"
                                @change="handleChange1"
                                :show-all-levels="false"
                                style="width: 100%"
                                clearable
                                >
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="角色">
                            <el-checkbox-group v-model="formData.role">
                                <el-checkbox v-for="item in roles" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                <!-- </div> -->
                <footer class="cus_drawer_footer">
                    <el-button size="small" @click="handleCancel">取消</el-button>
                    <el-button size="small" v-if="operateType !== 'detail'" type="primary" @click="handleSure">确定</el-button>
                </footer>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { reqUsersCreate, reqUsersDetail, reqUsersEdit } from '@/api/system/user'
import { reqAdminDepartmentTree } from '@/api/common/common'

export default {
    /* 用户的新增编辑 */
    name: 'CedUser',

    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        cedDrawVisible: {
            type: Boolean,
            default: false
        },
        operateType: {
            type: String,
            default: ''
        },
        allRoles: {
            type: Array,
            default: () => ([])
        }
    },

    data () {
        // const validateFn = (rule, value, callback) => {
        //     const { field } = rule
        //     if (field === 'username') {
        //         if (this.$utils.validateName(value)) {
        //             callback()
        //         } else {
        //             callback(new Error('请输入以字母开头的名称'))
        //         }
        //     } else if (field === 'phone') {
        //         if (this.$utils.validateMobilePhone(value)) {
        //             callback()
        //         } else {
        //             callback(new Error('请输入正确的手机号'))
        //         }
        //     } else if (field === 'email') {
        //         if (this.$utils.validateMail(value)) {
        //             callback()
        //         } else {
        //             callback(new Error('请输入正确的邮箱地址'))
        //         }
        //     }
        //     callback()
        // }

        return {
            title: '',
            drawerVisible: false,
            formData: {
                username: '',
                password: '',
                name: '',
                phone: '',
                email: '',
                tel: '',
                role: [],
                deptId: ''
            },
            rules: {
                // username: [{ required: true, trigger: 'blur', validator: validateFn }],
                username: [{ required: true, trigger: 'blur', message: '请输入' }],
                password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
                name: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
                phone: [{ required: true, trigger: 'blur', message: '请输入' }],
                email: [{ required: true, trigger: 'blur', message: '请输入' }],
                deptId: [{ required: true, trigger: 'blur', message: '请选择' }]
            },
            statusList: this.$utils.dict('deadlineStatus'),
            currentDetail: {},
            isDetail: false,
            roles: [],
            options: [],
            val: []
        }
    },
    created () {
        reqAdminDepartmentTree().then((res) => {
            console.log(res)
            this.options = res.data
        })
    },
    watch: {
        cedDrawVisible (v) {
            this.drawerVisible = v
            if (v) {
                this.handleInit()
                this.roles = this.allRoles
            } else {
                this.$refs.form.resetFields()
            }
        }
    },

    methods: {
        handleInit () {
            const { operateType: ot } = this
            if (ot === 'create') {
                this.title = '新增'
            } else if (ot === 'edit') {
                this.title = '编辑'
                this.getDetail()
            } else {
                this.title = '详情'
                this.getDetail()
            }
            this.isDetail = ot === 'detail'
        },

        handleCallback () {
            this.$emit('callback', false)
        },

        handleClose () {
            this.drawerVisible = false
            this.handleCallback()
        },

        handleCancel () {
            this.handleClose()
        },

        setFormData () {
            const { currentDetail, formData } = this
            Object.keys(formData).forEach(key => {
                if (key !== 'password') {
                    this.formData[key] = currentDetail[key]
                }
            })
            formData.role = currentDetail.roleList.map(v => v.roleId)
        },

        getDetail () {
            const { id } = this
            reqUsersDetail({ id })
                .then(({ data }) => {
                    this.currentDetail = data

                    this.setFormData()
                })
        },

        handleSure () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const { operateType: ot } = this
                    const { formData } = this
                    if (ot === 'create') {
                        reqUsersCreate(formData)
                            .then(() => {
                                this.$message.success('成功')
                                this.handleCallback()
                            })
                    }
                    if (ot === 'edit') {
                        reqUsersEdit({ userId: this.id, ...formData })
                            .then(() => {
                                this.$message.success('成功')
                                this.handleCallback()
                            })
                    }
                }
            })
        },
        handleChange1 (value) {
            console.log(value, this.$refs.myCascader.getCheckedNodes()[0].pathLabels[this.$refs.myCascader.getCheckedNodes()[0].pathLabels.length - 1])
            console.log(value, this.$refs.myCascader.getCheckedNodes()[0].pathLabels)
            this.formData.deptId = Number(value)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
