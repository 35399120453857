import request from '@/api/request'

/* 用户列表 */
export function reqUsers (params) { return request({ url: '/user/page', params }) }
/* 创建用户 */
export function reqUsersCreate (params) { return request({ url: '/user', method: 'post', params }) }
/* 编辑用户 */
export function reqUsersEdit (params) { return request({ url: '/user', method: 'put', params }) }
/* 用户详情 */
export function reqUsersDetail (params) { return request({ url: '/user/:id', method: 'get', params }) }
/* 用户删除 */
export function reqUsersDelete (params) { return request({ url: '/user/:id', method: 'delete', params }) }
/* 冻结 */
export function reqUsersFreeze (params) { return request({ url: '/user/lock/:id', method: 'put', params }) }
/* 解冻 */
export function reqUsersUnFreeze (params) { return request({ url: '/user/unlock/:id', method: 'put', params }) }
/* 修改手机号、头像、密码，等 */
export function reqUserInfoUpdate (params) { return request({ url: '/user/edit', method: 'put', params }) }
